.report-type-wrapper {
  position: relative;

  .next-button {
    position: absolute;
    right: 0;
    margin-top: 16px;
  }
}

.report-job-wrapper {
  .tag-wrapper {
    line-height: 1.5715;
    width: 100%;
    display: inline-block;
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    height: 32px;
  }

  .ant-spin {
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    border-radius: 4px;
    width: 100%;
  }

  > form {
    flex: 1;

    > .report-button-wrapper {
      margin-top: 32px;
      text-align: right;

      > .ant-form-item {
        display: inline-block;
      }

      button:not(first-child) {
        margin-left: 8px;
      }
    }
  }
}

.checkbox-wrapper {
  width: 400px;
  margin-left: 40px;
  border: #f1f1f1 1px solid;
  border-radius: 2px;
  padding: 12px;

  > .ant-checkbox-group {
    width: 100%;
  }

  .ant-space {
    width: 100%;

    > .ant-space-item {
      width: 100%;
      padding: 12px;
      border-bottom: #f1f1f1 1px solid;
    }
  }
}

.report-name-modal {
  .ant-modal-header {
    border: none;
  }

  .ant-modal-body {
    padding: 0 24px 12px;
  }

  .ant-modal-footer {
    border: none;
  }
}
