.layout-header-trigger {
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #1890ff;
  }
}

.layout-sider-logo {
  height: 32px;
  margin: 16px;
  align-items: center;

  h1 {
    margin: 0;
    color: #1f1f1f;
    font-size: 20px;
  }
}

.site-layout {
  min-height: 100vh;

  .site-layout-background {
    background: #fff;
  }
}