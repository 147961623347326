.report-form-wrapper {
  > div:first-child {
    .ant-typography {
      display: inline-block;
      margin-right: 12px;
    }

    button {
      display: inline-block;
      margin-bottom: 10px;
    }
  }
}
