.detail-param-container {
  tr {
    width: 100%;
  }

  th {
    vertical-align: top;
    padding: 0 24px 12px 0;
  }

  p {
    padding-bottom: 12px;
    margin-bottom: 24px;
    border-bottom: 1px solid #d2d8df;
  }

  td {
    word-break: break-all;
  }
}

.form {
  min-height: 60px;
  margin-bottom: 12px;
}

.detail-buttons-container {
  text-align: right;
  margin-bottom: 12px;

  button {
    margin-left: 10px;
  }
}

.test-container {
  display: grid;
  grid-template-columns: repeat(3, 24px) fit-content(160px);

  .key {
    margin-right: 24px;
    font-weight: bold;
    padding: 8px 0;
  }

  .heading {
    font-size: 16px;
    color: #343434;
  }

  .value {
    border-bottom: 1px solid #d9d9d9;
    padding: 8px 0;
    margin-bottom: 12px;
  }

  .border {
    width: 0;
    border: #a7a7a7 1px solid;
    background-color: #ececec;
    margin: 0 5px;
  }
}

.json-container {
  display: grid;
  grid-template-columns: auto auto;

  .json-value {
    width: 200px;
  }
}

.detail-schedule-container {
  width: calc(50% - 26px);
  margin: 0 6px 34px;

  .desc {
    font-size: 10px;
  }

  .value {
    width: 200%;
    color: #37414f;
    padding-bottom: 12px;
    margin-bottom: 20px;
    border-bottom: 1px solid #d2d8df;
    word-break: break-all;
  }

  .job-id {
    display: inline-block;
    float: right;
    font-size: 6px;
    color: rgb(143, 148, 155);
  }
}

.copyTest {
  display: inline-block;
}
