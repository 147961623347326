.json-object-container {
  display: grid;
  width: 100%;
  padding-top: 2px;
  grid-column: 1 / span 2;
  grid-template-columns: 24px auto;
  grid-template-rows: auto auto;
  grid-template-areas: "hd hd" "bd vc";

  &:not(:first-child) {
    margin-top: 16px;
  }

  .json-object-header {
    grid-area: hd;
    font-weight: 600;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
    grid-column-end: 1 / 1;
  }

  .json-object-border {
    grid-area: bd;
    width: 4px;
    background-color: #e2e2e2;
    margin: 8px 5px 12px;
  }
}

.json-value-container {
  display: grid;
  grid-area: vc;
  width: 100%;
  grid-template-columns: fit-content(120px) auto;

  .json-value-keyname {
    grid-column-start: 1;
    font-weight: 600;
    padding: 12px 24px 12px 0;
    margin: 0;
  }

  .json-value-value {
    padding: 12px 0;
    border-bottom: 1px solid #d2d8df;
    word-break: break-all;
    margin-bottom: 12px;
  }
}